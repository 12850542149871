import { FaXTwitter, FaSquareInstagram, FaFacebook } from "react-icons/fa6";
import classes from './SocialMedia.module.css';
import { Link } from 'react-router-dom';

export default function SocialMedia(){
    return(
        <div className={classes.SocialMedia}>
            {/* <Link to="" target="_blank"><FaXTwitter /></Link> */}
            <Link to={{ pathname:`https://www.instagram.com/thehighlandsnailsalon/`}} target="_blank"><FaSquareInstagram /></Link>
            <Link to={{ pathname:`https://www.facebook.com/p/The-Highlands-Nail-Salon-100091709491233/?locale=en_GB`}} target="_blank"><FaFacebook /></Link>
        </div>
    )
}