import React from 'react';
import classes from './GiftVoucher.module.css';

const GiftVoucher = (props) => {
    return(
        <div onClick={props.clicked.bind(this, props.amount)} className={classes.GiftVoucher}>
            <h1>£{props.amount}</h1>
        </div>
    )
}

export default GiftVoucher