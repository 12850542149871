import React from 'react';
import classes from './BasketMainControls.module.css';

const BasketMainControls = (props) => {

   let shownButtons =   <div className={classes.BasketMainControls}>
                            <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.toggleBasket}>Continue Shopping</button>
                            <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.customerDetailsForm}>Checkout</button>
                        </div>

    switch(props.basketStatus) {
        case "empty":
            shownButtons =  <div className={classes.BasketMainControls}>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.toggleBasket}>Continue Shopping</button>
                            </div>
            break;
        case "customer-details":
            shownButtons =  <div className={classes.BasketMainControls}>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.checkoutView.bind(this, "main")}>Back to Basket</button>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={(e) => props.customerDetailsErrorChecking(props.voucherTotalCost, e)}>Next</button>
                            </div>
            break;
        case "complete-gift-voucher":
            shownButtons =  <div className={classes.BasketMainControls}>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.checkoutView.bind(this, "main")}>Back to Basket</button>
                            </div>
            break;
        case "book-salon-treatments":
            shownButtons =  <div className={classes.BasketMainControls}>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.checkoutView.bind(this, "main")}>Back to Basket</button>
                                <button className={"customButton " + classes.bookTreatmentsButton} onClick={(e) => props.treatmentErrorCheckHandler(props.totalCost, e)}>Finish</button>
                            </div>
            break;
        case "payment_successful":
            shownButtons =  <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.checkIfSalonTreatmentsInBasket}>{props.twoPartPayment}</button>
            break;
        case "booking_completed":
            shownButtons = <button className={"customButton " + classes.bookTreatmentsButton} onClick={props.enquirySentButton}>Close</button>
            break;
      }

    return(
        <div className={classes.BasketMainControlsContainer}>
            {shownButtons}
        </div>
    )

}

export default BasketMainControls