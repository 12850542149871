import React, { Component } from 'react';
import classes from './SingleSalonTreatment.module.css';
import Axios from 'axios';
import { Link, Redirect, withRouter } from 'react-router-dom';
import CONST from '../../constants/constants';
import GoBack from '../../components/Ui/GoBack/GoBack';
import GuildLogo from '../../components/Ui/GuildLogo/GuildLogo';
import { MdTimer } from "react-icons/md";
import { BiXCircle, BiEdit } from "react-icons/bi";
import ConfirmDelete from '../../components/Ui/ConfirmDelete/ConfirmDelete';
import Aux from '../../hoc/Auxilary/Auxilary';
import Latest from '../../components/Ui/Navigation/Latest/Latest';
import { TiShoppingCart } from "react-icons/ti";
import FUNCTIONS from '../../functions/functions';
import logoImage from '../../components/Ui/Navigation/Header/Logo/logo.png';
import FlashMessage from '../../components/Ui/FlashMessage/FlashMessage';

class SingleSalonTreatment extends Component {

    state = {
        title: "",
        duration: "",
        image: "",
        price: "",
        description: "",
        prices: [],
        adminButtons: "",
        redirectOnSuccess: "",
        confirmDelete: "",
        open: false,
        subCategoryTitle: "",
        multiPriceSelected: "default",
        priceSelected: "",
        nailArt: false
    }

    componentDidMount() {

        let adminButtons = "";

        if(this.props.auth){
            adminButtons = <div className="admin-buttons">
                                <BiXCircle className="delete" 
                                onClick={this.confirmDeleteHandler} 
                            /> 
                                <Link to={"/admin/edit-salon-treatment/" + this.props.match.params.id}><BiEdit className="edit" /></Link>
                            </div>;
        }
        Axios.get(CONST.BASE_URL + '/api/single-salon-treatment/' + this.props.match.params.id).then(response => {
            FUNCTIONS.scrollToTop();
            this.setState({
                title: response.data.singleSalonTreatment.title,
                duration: response.data.singleSalonTreatment.duration,
                image: response.data.singleSalonTreatment.image,
                price: response.data.singleSalonTreatment.price,
                description: response.data.singleSalonTreatment.description,
                adminButtons: adminButtons,
                subCategoryTitle: response.data.singleSalonTreatment.category.title,
                prices: response.data.prices,
                nailArt: response.data.singleSalonTreatment.nail_art
            });
        }).catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
        
          });

    };

    removeConfirmDeleteHandler = () => {
        this.setState({
            confirmDelete: "",
            open: false
        })
    }

    confirmDeleteHandler = () => {
        this.setState({
            open: true,
            confirmDelete: <ConfirmDelete delete={this.deleteHandler} clicked={this.removeConfirmDeleteHandler} />
        })
    }

    deleteHandler = () => {
        Axios.defaults.withCredentials = true;
        Axios.delete(CONST.BASE_URL + '/api/delete-single-salon-treatment/' + this.props.match.params.id).then(response => {
            if(response.data.message === "Treatment Successfully Deleted"){
                this.setState({
                    redirectOnSuccess: <Redirect to={{
                                            pathname: "/admin",
                                            state: { fromRedirect: response.data.message }
                                            }}                  
                                        />
                })
            }
        });
    }

    changeHandler = (event) => {

        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        });
    }

    render(){

        // Define a JavaScript function called timeConvert with parameter n
        function timeConvert(n) {
            // Store the input number of minutes in a variable num
            let num = n;
            // Calculate the total hours by dividing the number of minutes by 60
            let hours = (num / 60);
            // Round down the total hours to get the number of full hours
            let rhours = Math.floor(hours);
            // Calculate the remaining minutes after subtracting the full hours from the total hours
            let minutes = (hours - rhours) * 60;
            // Round the remaining minutes to the nearest whole number
            let rminutes = Math.round(minutes);
            // Construct and return a string representing the conversion result

            let moreThanOnehour = "";
            if(rhours > 1){
                moreThanOnehour = "s";
            }

            let moreThanOneMinute = "";
            if(rminutes > 1){
                moreThanOneMinute = "s";
            }

            let amountofHours = rhours + " hour" + moreThanOnehour;

            if (rhours < 1) {
                amountofHours = "";
            }

            let amountofMinutes = rminutes + " minute" + moreThanOneMinute

            if (rminutes < 1) {
                amountofMinutes = "";
            }

            let andDisplayed = " and ";

            if(rhours < 1 || rminutes < 1){
                andDisplayed = "";
            }

            return amountofHours + andDisplayed + amountofMinutes;
        }

        let courseImage = this.state.image ? CONST.BASE_URL + "/storage/images/salon-treatment-images/single-salon-treatment-images/" + this.state.image : logoImage;

        let successMsg = "";
        
        let showSuccessMsg = (message) => {
            successMsg = (
                <FlashMessage message={message} duration={5000} />
            );
        };

        if (this.props.location.state !== undefined) {
            const message = this.props.location.state.fromRedirect;
            if (message) {
                showSuccessMsg(message);
            }
        }

        let shownButton = "";

        if(!this.props.auth){

            let price = this.state.price;
            let title = this.state.title;

            if(this.state.prices.length !== 0){
                price = parseFloat(this.state.multiPriceSelected);
                price = Number(price);
                title = this.state.title + " " + this.state.multiPriceSelected.replace(/[0-9]/g, '') + " multi-price";
            }

            // Chech if multi price or single price

            if(!this.props.auth){
                shownButton = <button className="customButton" onClick={this.props.addToShoppingBasket.bind(this, this.props.match.params.id + CONST.ST, "", "", title, price, this.state.subCategoryTitle, CONST.ST, courseImage)}>Add to Basket</button>;

                if(this.state.prices.length !== 0){
                    if(this.state.multiPriceSelected === "default"){
                        shownButton = "";
                    } else if(JSON.parse(localStorage.getItem("basketItems"))){
                        if(FUNCTIONS.checkBasket(Number(this.props.match.params.id) + CONST.ST)){
                            shownButton = <button className="customButton" onClick={this.props.toggleBasket}><TiShoppingCart />Continue to Basket</button>;
                        } else {
                            shownButton = <button className="customButton" onClick={this.props.addToShoppingBasket.bind(this, Number(this.props.match.params.id) + CONST.ST, "", "", title, price, this.state.subCategoryTitle, CONST.ST, courseImage)}>Add to Basket</button>;
                        }
                    }
                } else if(JSON.parse(localStorage.getItem("basketItems"))){
                    if(FUNCTIONS.checkBasket(Number(this.props.match.params.id) + CONST.ST)){
                        shownButton = <button className="customButton" onClick={this.props.toggleBasket}><TiShoppingCart />Continue to Basket</button>;
                    } else {
                        shownButton = <button className="customButton" onClick={this.props.addToShoppingBasket.bind(this, Number(this.props.match.params.id) + CONST.ST, "", "", title, price, this.state.subCategoryTitle, CONST.ST, courseImage)}>Add to Basket</button>;
                    }
                } else {
                    shownButton = <button className="customButton" onClick={this.props.addToShoppingBasket.bind(this, Number(this.props.match.params.id) + CONST.ST, "", "", title, price, this.state.subCategoryTitle, CONST.ST, courseImage)}>Add to Basket</button>;
                }
            }

            
        }

        let price = <div className={classes.stat}>
                        <h2>£</h2>
                        <h2>{this.state.price}</h2>
                    </div>

        if(this.state.prices.length !== 0){
            price = <select
                        className={classes.stat}
                        name="multiPriceSelected"
                        value={this.state.multiPriceSelected}
                        onChange={this.changeHandler}
                    >
                        <option value="default">How Many Custom Nails</option>
                        {this.state.prices.map(price =>
                            <option key={price.id} name={price.title} value={price.price + " " + price.title}>{price.title} - £{price.price}</option>
                        )}
                    </select>
        }

        let nailArt = "";

        if(this.state.nailArt){
            nailArt = <p className={classes.nailArtMessage}>Any Nail art is charged at £15 per hour</p>
        }

        return(
            <Aux>
                <Latest message={this.state.subCategoryTitle} />
                <div className={classes.SingleSalonTreatment}>
                    {successMsg}
                    {this.state.redirectOnSuccess}
                    {this.state.confirmDelete}
                    <GoBack back={() => this.props.history.goBack()} />
                    <h3 className={classes.title}>{this.state.title}</h3>
                    <img src={courseImage} alt="salon treatment" className={classes.treatmentImage} />
                    {shownButton}
                    <div className={classes.statsContainer}>
                        <div className={classes.stat}>
                            <MdTimer />
                            <h2>{timeConvert(this.state.duration)}</h2>
                        </div>
                        {price}
                        {nailArt}
                    </div>
                    {this.state.description.split("\n").map((i,key) => {
                        return <h3 className={classes.description} key={key}>{i}</h3>;
                    })}
                    <GuildLogo />
                    {this.state.adminButtons}
                </div>
            </Aux>
        )
    }
}

export default withRouter(SingleSalonTreatment)