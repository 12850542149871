import React from 'react';
import classes from './BasketMain.module.css';
import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import StripePaymentForm from './StripePaymentForm/StripePaymentForm';

const stripePromise = loadStripe('pk_test_51KwVSIC7O3LzD5wBnxb3SaYuZYrCQIuiRBBlW1WQ8tsQeU9UMDfyuaEEINx8iLOqOHy7xqrtkHfYxK17aT457Tll00BUdIrkEd');

const BasketMain = (props) => {

    let content =   <div className={classes.basketContainer}>
                        {props.currentBasketItems}
                    </div>

    switch(props.basketStatus) {
        case "customer-details":
            content =   <div className={classes.basketContainer}>
                            <input 
                                type="text" 
                                placeholder="Full Name"
                                name="bookingRequestName"
                                autoComplete="off"
                                value={props.bookingRequestName}
                                onChange={props.changeHandler}
                            />
                            {props.bookingRequestNameError}
                            <input 
                                type="text" 
                                placeholder="Email Address"
                                name="bookingRequestEmail"
                                autoComplete="off"
                                value={props.bookingRequestEmail}
                                onChange={props.changeHandler}
                            />
                            {props.bookingRequestEmailError}
                            <input 
                                type="number" 
                                placeholder="Contact Number"
                                name="bookingRequestNumber"
                                autoComplete="off"
                                value={props.bookingRequestNumber}
                                onChange={props.changeHandler}
                            />
                            {props.bookingRequestNumberError}
                        </div>
            break;
            case "complete-gift-voucher":
                content =   <div className={classes.basketContainer}>
                                <p>{props.twoPartPayment}</p>
                                <p>Total voucher amount to pay: £{props.voucherTotalCost}</p>
                                <Elements stripe={stripePromise} options={props.options}>
                                    <StripePaymentForm statusView={props.statusView} finishVoucherPaymentHandler={props.finishVoucherPaymentHandler} vouchertotalCost={props.voucherTotalCost}/>
                                </Elements>      
                            </div>
            break;
            case "complete-gift-voucher":
                content =   <div className={classes.basketContainer}>
                                <div className={classes.customerMessageContainer}>
                                    <p className="success">
                                        Thank you. Your payment was successful. Your voucher will be emailed to you shortly
                                    </p>
                                    {props.whatNext}
                                </div>
                            </div>
            break;
            case "booking_completed":
                content =   <div className={classes.basketContainer}>
                                <div className={classes.customerMessageContainer}>
                                    <h3 className="success">
                                        Thank you for your enquiry. The Highlands Nail Salon will get back to you as soon as possible with availability
                                    </h3>
                                </div>
                            </div>
            break;
    }

    return (
        <div className={classes.basketMain} >
            {content}
        </div>
    )
}

export default BasketMain