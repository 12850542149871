import React, { useState } from 'react';
import classes from './Basket.module.css';
import Aux from '../../../hoc/Auxilary/Auxilary';
import CONST from '../../../constants/constants';
import Logo from '../../../components/Ui/Navigation/Header/Logo/Logo';
import { format } from 'date-fns';
import { FcCalendar } from "react-icons/fc";
import Backdrop from '../Backdrop/Backdrop';
import BasketHeader from './BasketHeader/BasketHeader';
import BasketItem from './BasketItem/BasketItem';
import BasketMainControls from './BasketMainControls/BasketMainControls';
import enGb from 'date-fns/locale/en-GB';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import addDays from 'date-fns/addDays';
import getDay from 'date-fns/getDay';
import FUNCTIONS from '../../../functions/functions';

import BasketMain from './BasketMain/BasketMain';
registerLocale('en-gb', enGb);

const Basket = (props) => {

    const [treatmentStartDate, settreatmentStartDate] = useState(new Date());
    const isWeekday = date => {
        const day = getDay(date);
        return day !== 0;
      };

    function onTreatmentStartDateChange(date) {
        settreatmentStartDate(date);
        props.treatmentsStartdateChangehandler(format(date, 'dd/MM/yyyy'));
    }

    function enquirySentButton() {
        props.toggleBasket();
        props.checkoutView(false);
    }

    let attachedClasses = [classes.Basket, classes.Hide];
    let totalCost = 0;
    let treatmentTotalCost = 0;
    let voucherTotalCost = 0;
    let currentBasketItems = <h2 className={classes.noItemsInBasket}>There are currently no items in your basket</h2>;
    let treatmentOnlyPriceArray = [];
    let voucherOnlyPriceArray = [];
    let totalPriceArray = [];
    let price = 0;

    if(props.itemsInBasket.length > 0){
        props.itemsInBasket.forEach(item => {
            price = item.price * item.quantity;
            totalPriceArray.push(price);
            if(item.type === "gift_voucher"){
                price = item.price * item.quantity;
                voucherOnlyPriceArray.push(price);
            } else if (item.type === "ST") {
                price = item.price * item.quantity;
                treatmentOnlyPriceArray.push(price);
            }

            return totalPriceArray, treatmentOnlyPriceArray, voucherOnlyPriceArray
        });

        voucherTotalCost = Number(voucherOnlyPriceArray.reduce(function(pv, cv) { return pv + cv; }, 0).toFixed(2));
        treatmentTotalCost = Number(treatmentOnlyPriceArray.reduce(function(pv, cv) { return pv + cv; }, 0).toFixed(2));

        totalCost = voucherTotalCost + treatmentTotalCost;

        // Sort basket items by salon treatment first then gift vouchers
        let sortedBasketItems = props.itemsInBasket.sort((a, b) => b.type.localeCompare(a.type));

        currentBasketItems =
        sortedBasketItems.map(item =>
            <BasketItem
                id={item.id}
                key={item.id}
                to={item.to}
                from={item.from}
                title={item.title}
                price={item.price * item.quantity}
                image={item.image}
                type={item.type}
                quantity={item.quantity}
                remove={props.remove}
                minus={props.minus}
                plus={props.plus}
                subCategoryTitle={item.subCategoryTitle}
            />
        )
    }

    // Smart check what basket items have been selected and show customer correct screen type of service
    
    // Get customer details
    function customerDetailsForm() {
        props.checkoutView("customer-details");
    }

    // Check if there are any salon treatments
    function checkIfSalonTreatmentsInBasket() {
        FUNCTIONS.checkBasket(CONST.ST)
        ? props.checkoutView("book-salon-treatments")
        : enquirySentButton();
    }

    if (props.showBasket) {
        attachedClasses = [classes.Basket, classes.Show];
    }

    let totalPrice =    <div className={classes.totalPrice}>
                            <p>Total: £ {totalCost}</p>
                        </div>

    // View of basket with goods in it
    let shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Your Basket" />
                <BasketMain currentBasketItems={currentBasketItems}/>
                {totalPrice}
                <BasketMainControls toggleBasket={props.toggleBasket} customerDetailsForm={customerDetailsForm} />
            </div>

    // View of basket with nothing in it
    if(props.itemsInBasket.length === 0){
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Your Basket" />
                <BasketMain currentBasketItems={currentBasketItems}/>
                {totalPrice}
                <BasketMainControls basketStatus="empty" toggleBasket={props.toggleBasket} />
            </div>
    }

    // Customer details form when checkout has been selected
    if(props.checkout === "customer-details"){
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Your Information" />
                <BasketMain
                    basketStatus="customer-details"
                    bookingRequestName={props.bookingRequestName}
                    bookingRequestNameError={props.bookingRequestNameError}
                    bookingRequestEmail={props.bookingRequestEmail}
                    bookingRequestEmailError={props.bookingRequestEmailError}
                    bookingRequestNumber={props.bookingRequestNumber}
                    bookingRequestNumberError={props.bookingRequestNumberError}
                    changeHandler={props.changeHandler}
                />
                {totalPrice}
                <BasketMainControls basketStatus="customer-details" checkoutView={props.checkoutView} customerDetailsErrorChecking={props.customerDetailsErrorChecking} voucherTotalCost={voucherTotalCost} />
            </div>
    }

    // View of basket when paying for a voucher
    if(props.checkout === "complete-gift-voucher"){
        let twoPartPayment = "";
        if (FUNCTIONS.checkIfGiftVouchersAndSalonTreatmentsInBasket()) {
            twoPartPayment = "Firstly let's get your voucher sorted";
        }
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Gift Voucher Payment" />
                <BasketMain
                    basketStatus="complete-gift-voucher"
                    twoPartPayment={twoPartPayment}
                    voucherTotalCost={voucherTotalCost}
                    options={props.options}
                    statusView={props.statusView}
                    finishVoucherPaymentHandler={props.finishVoucherPaymentHandler}
                />
                <div className={classes.totalPrice}>            
                </div>
                <BasketMainControls basketStatus="complete-gift-voucher" checkoutView={props.checkoutView} customerDetailsErrorChecking={props.customerDetailsErrorChecking} voucherTotalCost={voucherTotalCost} />
            </div>
    }

    // View once voucher has been successfully paid for and check if there are any salon treatments
    if(props.checkout === "payment_successful"){
        let twoPartPayment = "Close";
        let whatNext = ""
        if (props.voucherPaid && FUNCTIONS.checkBasket(CONST.ST)) {
            twoPartPayment = "Continue";
            whatNext =  <p className="success">
                            Tap Continue to book your Salon Treatments
                        </p>
        }
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Thank You" />
                <BasketMain  basketStatus="payment_successful" whatNext={whatNext}/>
                <div className={classes.totalPrice}>            
                </div>
                <BasketMainControls basketStatus="payment_successful" checkIfSalonTreatmentsInBasket={checkIfSalonTreatmentsInBasket} twoPartPayment={twoPartPayment} />
            </div>
    }

    if(props.statusView === "payment-status-view"){
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Payment Status" />
                <div className={classes.basketContainer}>
                    <h1>Payment status</h1>
                </div>     
            </div>
    }

    // View when customer is booking date and time for salon treatments
    if(props.checkout === "book-salon-treatments"){
        let today = new Date();
        let shownDateChoiceMessage = <h3>Select Preferred Salon Treatments Date</h3>;
        let shownDateChoice = "";
        if(format(treatmentStartDate, 'dd/MM/yyyy') !== format(today, 'dd/MM/yyyy')){
            shownDateChoiceMessage = <h3>Your Selected Salon Treatments Date is:</h3>;
            shownDateChoice =   <div>
                                    <h2>{format(treatmentStartDate, 'dd/MM/yyyy')}</h2>
                                    <br></br>
                                    <h4>(Tap Calander to change)</h4>
                                </div>;
        }
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Your Salon Treatments" />
                <div className={classes.basketContainer}>
                    <div className={classes.calanderContainer}>
                        <DatePicker
                            locale="en-gb"
                            dateFormat={'dd/MM/yyyy'}
                            placeholderText="Please select a date"
                            onChange={onTreatmentStartDateChange}
                            minDate={new Date()}
                            maxDate={addDays(new Date(), 330)}
                            customInput={
                                <div className={classes.calanderImagecontainer}>
                                    <FcCalendar />
                                    {shownDateChoiceMessage}
                                    {shownDateChoice}
                                </div>
                            }
                            filterDate={isWeekday}
                        />
                    </div>
                    {props.treatmentsStartdateError}
                    <select
                            name="bookingRequestTime"
                            value={props.bookingRequestTime}
                            onChange={props.changeHandler}
                        >
                        <option value="select">Select preferred Time</option>
                        <option value="morning">Morning</option>
                        <option value="afternoon">Afternoon</option>
                        <option value="evening">Evening</option>
                    </select>
                    {props.bookingRequestTimeError}
                    <label className={classes.atLeast16}>
                        <input type="checkbox" onChange={props.checkBoxChangeHandler} name="atLeast16" value={props.atLeast16}/>
                        <p>Are you 16 years of age or above?</p>
                    </label>
                    {props.atLeast16Error}
                </div>
                {totalPrice}
                <BasketMainControls basketStatus="book-salon-treatments" checkoutView={props.checkoutView} treatmentErrorCheckHandler={props.treatmentErrorCheckHandler} totalCost={totalCost} />
            </div>
    }

    // View of basket once the customer has completed treatment enquiry
    if(props.checkout === "booking_completed"){
        shownBasketInfo = 
            <div className={attachedClasses.join(' ')}>
                <BasketHeader title="Thank You" />
                <BasketMain basketStatus="booking_completed" />
                <div className={classes.totalPrice}>            
                </div>
                <BasketMainControls basketStatus="booking_completed" enquirySentButton={enquirySentButton} />
            </div>
    }

    return(
        <Aux>
            <Backdrop full={props.showBasket} clicked={props.toggleBasket} showBasket={props.showBasket} toggleCheckout={props.toggleCheckout}/>
            {shownBasketInfo}
        </Aux>
    )
}

export default Basket;